import React from "react";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import ServiceArea from "../HomeOne/ServiceArea";
import MultipleArea from "../HomeTwo/MultipleArea";
import FooterOne from "../Layouts/Footers/FooterOne";

function Services() {
  return (
    <>
      <HeaderThree />
      <main>
        <InnerPageTitle
          title="Services"
          paths={[
            { name: "Home", path: "/" },
            { name: "Services", path: "/services" },
          ]}
        />
        <ServiceArea className="inner-services-area pt-115 pb-90" />
        <MultipleArea />
      </main>
      <FooterOne />
    </>
  );
}

export default Services;
