import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function MainWid() {

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const brochureType = queryParams.get("brochureType");

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [location]);

  const brochureLinks = {
    palmParadise: "/The_Palm_Paradise.pdf",
    palmHub: "/A3_Broucher-Palm-14th_June.pdf",
  };

  const titleText =
    brochureType === "palmParadise" ? "Book Your Dream Home" :
      brochureType === "palmHub" ? "Book Your Business Destination" : "Book Your Property";

  const brochureLink = brochureLinks[brochureType];

  const buttonText =
    brochureType === "palmParadise" ? "Download Palm Paradise Brochure" :
      brochureType === "palmHub" ? "Download Palm Business Hub Brochure" : "Send Message";

  function validateNumberInput(event) {
    event.target.value = event.target.value.replace(/[^0-9]/g, '').slice(0, 10);
  }

  const handleSubmit = event => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);
    console.log("form data", myForm);

    if (brochureType && brochureLink) {
      // Trigger brochure download
      const a = document.createElement("a");
      a.href = brochureLink;
      a.download = brochureLink.split("/").pop();
      a.click();


      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString()
      })
        .then(() => toast.success("Thank you for your submission"))
        .catch(error => toast.error(error));
    } else {
      // If no brochureType, just send the form data to email
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString()
      })
        .then(() => toast.success("Thank you for your submission"))
        .catch(error => toast.error(error));
    }
  };


  const isLongText = buttonText.length > 25;

  return (
    <section className="contact-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-10">
            <div
              className="contact-form-wrap"
              style={{
                backgroundImage: `url(${require("../../assets/img/images/contact_form_bg.jpg")})`,
              }}
            >
              <h2 className="title">{titleText}</h2>
              <p>Our experts will respond to you soon</p>
              <form className="contact-form" name="contact" data-netlify="true" method="post" onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="firstName"
                        type="text"
                        name="name"
                        placeholder="Name*"
                        required />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="lastName"
                        type="text"
                        placeholder="Last Name*"
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="phone"
                        type="text"
                        name="phoneno"
                        maxLength="10"
                        placeholder="Phone Number*"
                        required
                        onInput={validateNumberInput}
                      />
                    </div>
                  </div>

                </div>
                <div className="">
                  <div className="form-grp">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email Address*"
                    />
                  </div>
                </div>
                {/* <div className="form-grp">
                  <input id="subject" type="text" placeholder="Subject" />
                </div> */}
                <div className="form-grp">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Your Message here"
                  ></textarea>
                </div>
                <button type="submit" className={`btn ${isLongText ? "long-text" : ""}`}>
                  {buttonText}
                </button>
                <ToastContainer />
              </form>
            </div>
          </div>
          <div className="col-xl-6 col-lg-10">
            <div className="contact-info-wrap">
              <h2 className="title">Need Any Help?</h2>
              <p>Call us or message and we' ll respond as soon as possible</p>
              <ul className="list-wrap ">
                <div className="row ">
                  <div className="col-lg-4">
                    <li>
                      <div className="contact-info-item " >
                        <div className="icon">
                          <i className="fas fa-phone-alt"></i>
                        </div>
                        <div className="content">
                          {/* <a href="tel:0123456789">+(323) 9847 3847 383</a> */}
                          <a href="tel:+91 99099 59910">+91 99099 59910</a>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div className="col-lg-4">
                    <li>
                      <div className="contact-info-item">
                        <div className="icon">
                          <i className="fas fa-envelope"></i>
                        </div>
                        <div className="content">
                          <a href="mailto:palmspringsrealities@gmail.com">palmspringsrealities<br />@gmail.com</a>
                          {/* <a href="mailto:infoyour@gmail.com">
                        domaininfo@gmail.com
                      </a> */}
                        </div>
                      </div>
                    </li>
                  </div>
                  <div className="col-lg-4">
                    <li>
                      <div className="contact-info-item">
                        <div className="icon">
                          <i className="fas fa-map-marker-alt"></i>
                        </div>
                        <div className="content">
                          <p>
                            The Palm Paradise by The Palm Group, Nava Vadaj, Ahmedabad
                          </p>
                        </div>
                      </div>
                    </li>
                  </div>

                </div>

              </ul>
            </div>
            <div id="contact-map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234974.56200800434!2d72.38826478133998!3d23.042948149198452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8341fc551eeb%3A0x55277f96958a08a1!2sThe%20Palm%20Paradise!5e0!3m2!1sen!2sin!4v1723036011131!5m2!1sen!2sin"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
