import React from "react";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import InnerPageTitle1 from "../Helpers/InnerPageTitle1";
import FooterOne from "../Layouts/Footers/FooterOne";
import PolicyPage from "./PolicyPage";


function index() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle1
        title="Privacy Policy"
        paths={[
          { name: "Home", path: "/" },
          { name: "Piavacy Policy", path: "/privacypolicy" },
        ]}
      />
      <PolicyPage />
      <FooterOne />
    </>
  );
}

export default index;
