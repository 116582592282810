import React from "react";

function Aboutus() {
    return (
        <>
            <section className="features-area pt-120 pb-90">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            <span className="sub-title tg-element-title">About Us</span>
                            <h2 className="title tg-element-title">The Palm Group: Building Legacies, Crafting Futures</h2>
                        </div>
                        <p className="li1">
                            Our flagship residential project, The Palm Paradise, offers luxurious
                            3BHK homes designed to provide unparalleled comfort and convenience.
                            With meticulous attention to detail and a focus on modern amenities,
                            The Palm Paradise is a testament to our commitment to excellence in
                            residential living.
                        </p>
                        <p className="li1">
                            Under the dynamic leadership of Rishil Shah,
                            The Palm Group continues to thrive, expanding its horizons
                            and setting new standards in the real estate sector. Our commitment
                            to creating exceptional residential and commercial spaces has earned
                            us the trust and respect of countless clients and stakeholders.
                        </p>

                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            {/* <span className="sub-title tg-element-title">Our Vision </span> */}
                            <h2 className="title tg-element-title mt-5">Our Vision</h2>
                        </div>
                        <p className="li1">
                            At The Palm Group, our vision is to redefine urban living 
                            and commercial landscapes by delivering projects that blend 
                            aesthetics, functionality, and sustainability. We aim to 
                            create environments that not only meet the evolving needs 
                            of our clients but also contribute to the betterment of the 
                            community and the environment.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Aboutus;
