import React from "react";

function AboutArea({ className }) {
  return (
    <section className={`about-area pb-120 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
            <div className="about-img-wrap">
              <img
                src={require(`../../assets/img/images/aboutus1.png`)}
                alt=""
                className="wow fadeInRight"
                data-wow-delay=".4s"
              />
              <img
                src={require(`../../assets/img/images/aboutus2.png`)}
                alt=""
                className="wow fadeInRight"
                data-wow-delay=".2s"
              />
              <div className="about-experiences-wrap">
                <div className="experiences-item">
                  <div className="icon">
                    <img
                      src={
                        require(`../../assets/img/icon/about_icon01.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">
                    3000+ Properties Sold
                    </h6>
                  </div>
                </div>
                <div className="experiences-item">
                  <div className="icon">
                    <img
                      src={
                        require(`../../assets/img/icon/about_icon02.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">
                    20+ Years of Legacy
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="about-content">
              <div className="section-title mb-25 tg-heading-subheading animation-style3">
                <span className="sub-title tg-element-title">
                  About Us
                </span>
                <h2 className="title tg-element-title">
                The Palm Group: Building Legacies, Crafting Futures
                </h2>
              </div>
              <p>
              Founded two decades ago by the visionary Suketu Shah, 
              The Palm Group has established itself as a prominent name in 
              the real estate industry. With a legacy of 20 years, we have 
              been at the forefront of developing properties that stand as 
              benchmarks of quality, innovation, and excellence.
              </p>
              <div className="about-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check"></i>Visionary Leadership and Expertise
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Commitment to Quality and Innovation
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Customer-Centric Approach
                  </li>
                </ul>
              </div>
              <a href="/about" className="btn">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutArea;
