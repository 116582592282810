import React from "react";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import InnerPageTitle1 from "../Helpers/InnerPageTitle1";
import MainWid from "./MainWid";
import FooterOne from "../Layouts/Footers/FooterOne";

function index() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle1
        title="Contact Us"
        paths={[
          { name: "Home", path: "/" },
          { name: "Contact", path: "/contact" },
        ]}
      />
      <MainWid />
      <FooterOne />
    </>
  );
}

export default index;
