import React from "react";
import FooterOne from "../Layouts/Footers/FooterOne";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import AboutArea from "../HomeOne/AboutArea";
// import WorkArea from "./WorkArea";
// import HistoryArea from "./HistoryArea";
import TeamArea from "../HomeOne/TeamArea";
import Aboutus from "./Aboutus";
// import TestimonialArea from "./TestimonialArea";
// import BrandAreaWid from "../Common/BrandAreaWid";

function index() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="About Us"
        paths={[
          { name: "Home", path: "/" },
          { name: "About", path: "/about" },
        ]}
      />
      <Aboutus />
      {/* <AboutArea className="pt-120" /> */}
      {/* <WorkArea /> */}
      {/* <HistoryArea /> */}
      {/* <div
        className="area-bg-five"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/area_bg05.jpg")})`,
        }}
      > */}
        {/* <TeamArea className="pt-0" /> */}
        {/* <TestimonialArea /> */}
      {/* </div> */}
      {/* <BrandAreaWid /> */}
      <FooterOne />
    </>
  );
}

export default index;
