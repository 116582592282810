import React from "react";

function FeatureArea() {
  return (
    <>
      <section className="features-area pt-120 pb-90">
        <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
              <h2 className="title tg-element-title">Our Values</h2>
            </div>
          </div>
        </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="features-item wow fadeInUp" data-wow-delay=".2s">
                <div className="features-item-inner">
                  <div className="features-content">
                    <h4 className="title">Integrity</h4>
                    <p>
                    We uphold the highest standards of integrity in all our actions, ensuring transparency and honesty in our dealings.
                    </p>
                    {/* <a href="/services/services-details" className="link-btn">
                      <i className="fas fa-arrow-right"></i> Read More
                    </a> */}
                  </div>
                  <div className="features-icon">
                    <img
                      src={
                        require(`../../assets/img/icon/iCON x pALM-01.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item wow fadeInUp" data-wow-delay=".4s">
                <div className="features-item-inner">
                  <div className="features-content">
                    <h4 className="title">Innovation</h4>
                    <p>
                    We embrace innovation, continuously seeking new and better ways to meet the needs of our clients and the industry.
                    </p>
                    {/* <a href="/services/services-details" className="link-btn">
                      <i className="fas fa-arrow-right"></i> Read More
                    </a> */}
                  </div>
                  <div className="features-icon">
                    <img
                      src={
                        require(`../../assets/img/icon/iCON x pALM-02.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="features-item wow fadeInUp" data-wow-delay=".6s">
                <div className="features-item-inner">
                  <div className="features-content">
                    <h4 className="title">Quality</h4>
                    <p>
                    We are committed to delivering superior quality in every project, ensuring the highest standards of construction and design.
                    </p>
                    {/* <a href="/services/services-details" className="link-btn">
                      <i className="fas fa-arrow-right"></i> Read More
                    </a> */}
                  </div>
                  <div className="features-icon">
                    <img
                      src={
                        require(`../../assets/img/icon/iCON x pALM-03.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureArea;
