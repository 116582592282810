import React, { useEffect, useState } from "react";
import SliderCom from "../Helpers/SliderCom";
import { useNavigate } from "react-router-dom";

function Hero() {

  const bannerImages = [
    require(`../../assets/img/banner/Banner1.png`),
    require(`../../assets/img/banner/Business-Banner-2.webp`)
  ];

  const navigate = useNavigate();

  const handleBrochureClick = (brochureType) => {
    window.scrollTo(0, 0);
    navigate(`/contact?brochureType=${brochureType}`);
  };

  const bannerContents = [
    {
      title: "The Palm Paradise 3BHK Luxury Living",
      description: "The Palm Group presents premium 3BHK homes in the prime location of Nava Vadaj, Ahmedabad, offering over 30 world-class amenities.",
      buttonText: "Get Directions",
      buttonLink: "https://www.google.com/maps?rlz=1C1CHBF_enIN892IN892&gs_lcrp=EgZjaHJvbWUqFQgBEC4YJxivARjHARiABBiKBRiOBTIPCAAQIxgnGOMCGIAEGIoFMhUIARAuGCcYrwEYxwEYgAQYigUYjgUyBggCEEUYOTINCAMQLhivARjHARiABDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDIHCAcQABiABDINCAgQABiGAxiABBiKBTINCAkQABiGAxiABBiKBdIBCDQyNjZqMGo3qAIAsAIA&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=KeseVfxBg145MaEIipWWfydV&daddr=Near,+Saurabh+School+Rd,+Vijay+Nagar,+Nava+Vadaj,+Ahmedabad,+Gujarat+380013",
      tooltipItems: [
        {
          title: "The Palm Paradise",
          description: "Download Brochure",
          brochureType: "palmParadise",
        },
        {
          title: "3BHK Luxurious Home",
          description: "A serene space surrounded by nature",
        },
        {
          title: "30+ Premium Amenities",
          description: "Jain Derasar, Banquet Hall, Dedicated Parking & more",
        },
      ],
    },
    {
      title: "The Palm Business Hub",
      description: "Presenting Individual Industrial Hubs perfect for Warehouses, Workshops, and Back Offices, with units ranging from 837 to 1602 square feet. Strategically situated in Narol, Ahmedabad, this thriving industrial location offers seamless connectivity to major highways, ensuring accessibility and growth for your business.",
      buttonText: "Get Directions",
      buttonLink: "https://www.google.com/maps/dir//the+palm+business+hub/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x395e8fe5f5926c87:0x52905daa500ac8a6?sa=X&ved=1t:3061&ictx=111",
      tooltipItems: [
        {
          title: "The Palm Business Hub",
          description: "Download Brochure",
          brochureType: "palmHub",  
        },
        // {
        //   title: "Features",
        //   description: "Discover the features of our new buildings.",
        // },
        // {
        //   title: "30+ Premium Amenities",
        //   description: "Jain Derasar, Banquet Hall, Dedicated Parking & more",
        // },
      ],
    },
  ];

  // Slider settings for changing the background images
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 4000,
    animation:'ease-in-out',
    autoplay: true,
    arrows: true,
    // infinite : true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // fade: true, // Fades between images instead of sliding
  };

  const sliderSettins = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: true,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  useEffect(() => {
    const getAllTooltip = document.querySelectorAll(".tooltip-item");
    getAllTooltip.forEach((item) => {
      item.querySelector(".tooltip-btn").addEventListener("click", () => {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        } else {
          item.classList.add("active");
        }
      });
    });
  });


  const [visibleTooltip, setVisibleTooltip] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (visibleTooltip !== null) {
      const tooltipTimer = setTimeout(() => {
        setVisibleTooltip(null);
      }, 3000);

      return () => clearTimeout(tooltipTimer);
    }
  }, [visibleTooltip]);

  const handleTooltipClick = (index) => {
    if (timer) clearTimeout(timer);
    setVisibleTooltip(index);

    const newTimer = setTimeout(() => {
      setVisibleTooltip(null);
    }, 3000);

    setTimer(newTimer);
  };
  const tooltipStyle = (isVisible) => ({
    opacity: isVisible ? 1 : 0,
    visibility: isVisible ? 'visible' : 'hidden',
    transition: 'opacity 0.3s linear, visibility 0.3s linear',
  });

  return (
    <section className="banner-area">
      <div
        className="banner-shape"
        style={{ background: `url(${require(`../../assets/img/banner/banner_shape.png`)})` }}
      ></div>
      <SliderCom settings={sliderSettings}>
        {bannerImages.map((image, index) => (
          <div key={index}>
            {/* The banner-bg class is dynamically changing with each slide */}
            <div
              className="banner-bg"
              style={{ background: `url(${image}) no-repeat center center/cover` }}
            >
              {/* Fixed content */}
              <div className="banner-content">
                <h2 className="title wow fadeInDown" data-wow-delay=".2s">
                  {bannerContents[index].title}
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".2s">
                  {bannerContents[index].description}
                </p>
                <a
                  href={bannerContents[index].buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  {bannerContents[index].buttonText}
                </a>
              </div>

              {/* Tooltip buttons */}
              <div className="banner-tooltip-wrap">
                {bannerContents[index].tooltipItems.map((item, itemIndex) => (
                  <div
                  key={itemIndex}
                  className={`tooltip-item ${itemIndex === 0 ? "top" : ""} ${visibleTooltip === item.brochureType ? "active" : ""}`}
                  onClick={() => handleTooltipClick(item.brochureType)} // Trigger navigation on click
                >
                  <div className="tooltip-btn pulse">
                    <i className="fas fa-plus"></i>
                  </div>
                  <div className="tooltip-content" style={tooltipStyle(visibleTooltip === item.brochureType)}>
                    <h2 className="title">{item.title}</h2>
                    {item.brochureType ? (
                        <a href={item.link} className="btn1" download onClick={() => handleBrochureClick(item.brochureType)}>
                          {item.description} <i className="fas fa-download"></i>
                        </a>
                      ) : (
                        <p>{item.description}</p>
                      )}
                  </div>
                </div>
                ))}
                {/* <div
                  className={`tooltip-item ${visibleTooltip === 1 ? "active" : ""}`}
                  onClick={() => handleTooltipClick(1)}
                >
                  <div className="tooltip-btn pulse">
                    <i className="fas fa-plus"></i>
                  </div>
                  <div className="tooltip-content" style={tooltipStyle(visibleTooltip === 1)}>
                    <h2 className="title">3BHK Luxurious Home</h2>
                    <p>A serene space surrounded by nature</p>
                  </div>
                </div>
                <div
                  className={`tooltip-item ${visibleTooltip === 2 ? "active" : ""}`}
                  onClick={() => handleTooltipClick(2)}
                >
                  <div className="tooltip-btn pulse">
                    <i className="fas fa-plus"></i>
                  </div>
                  <div className="tooltip-content" style={tooltipStyle(visibleTooltip === 2)}>
                    <h2 className="title">30+ Premium Amenities</h2>
                    <p>Jain Derasar, Banquet Hall, Dedicated Parking & more</p>
                  </div>
                      </div>*/}
              </div>
            </div>
          </div> 
        ))}

      </SliderCom>
      {/*<div
        className="banner-bg"
        style={{ background: `url(${require(`../../assets/img/banner/Banner1.png`)})` }}
      >
        <div className="banner-content">
          <h2 className="title wow fadeInDown" data-wow-delay=".2s">
            The Palm Paradise 3BHK Luxury Living
          </h2>
          <p className="wow fadeInUp" data-wow-delay=".2s">
            The Palm Group presents premium 3BHK homes in the prime location of
            Nava Vadaj, Ahmedabad, offering over 30 world-class amenities.
          </p>
          <a
            href="https://www.google.com/maps?rlz=1C1CHBF_enIN892IN892&gs_lcrp=EgZjaHJvbWUqFQgBEC4YJxivARjHARiABBiKBRiOBTIPCAAQIxgnGOMCGIAEGIoFMhUIARAuGCcYrwEYxwEYgAQYigUYjgUyBggCEEUYOTINCAMQLhivARjHARiABDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDIHCAcQABiABDINCAgQABiGAxiABBiKBTINCAkQABiGAxiABBiKBdIBCDQyNjZqMGo3qAIAsAIA&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=KeseVfxBg145MaEIipWWfydV&daddr=Near,+Saurabh+School+Rd,+Vijay+Nagar,+Nava+Vadaj,+Ahmedabad,+Gujarat+380013"
            target="_blank"
            rel="noopener noreferrer"
            className="btn wow fadeInUp"
            data-wow-delay=".4s"
          >
            Get Directions
          </a>
        </div>
        <div className="banner-tooltip-wrap">
          <div
            className={`tooltip-item top ${visibleTooltip === 0 ? "active" : ""}`}
            onClick={() => handleTooltipClick(0)}
          >
            <div className="tooltip-btn pulse">
              <i className="fas fa-plus"></i>
            </div>
            <div className="tooltip-content" style={tooltipStyle(visibleTooltip === 0)}>
              <h2 className="title">The Palm Paradise</h2>
              <a href="/The_Palm_Paradise.pdf" className="btn1" download>
                Download Brochure <i className="fas fa-download"></i>
              </a>
            </div>
          </div>
          <div
            className={`tooltip-item ${visibleTooltip === 1 ? "active" : ""}`}
            onClick={() => handleTooltipClick(1)}
          >
            <div className="tooltip-btn pulse">
              <i className="fas fa-plus"></i>
            </div>
            <div className="tooltip-content" style={tooltipStyle(visibleTooltip === 1)}>
              <h2 className="title">3BHK Luxurious Home</h2>
              <p>A serene space surrounded by nature</p>
            </div>
          </div>
          <div
            className={`tooltip-item ${visibleTooltip === 2 ? "active" : ""}`}
            onClick={() => handleTooltipClick(2)}
          >
            <div className="tooltip-btn pulse">
              <i className="fas fa-plus"></i>
            </div>
            <div className="tooltip-content" style={tooltipStyle(visibleTooltip === 2)}>
              <h2 className="title">30+ Premium Amenities</h2>
              <p>Jain Derasar, Banquet Hall, Dedicated Parking & more</p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="brand-area">
        <div className="container">
          {/* <div className="brand-inner">
            <div className="brand-active row position-relative">
              <SliderCom settings={sliderSettins}>
                <div className="col-12">
                  <div className="brand-item">
                    <img
                      src={require(`../../assets/img/brand/brand_img01.png`)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="brand-item">
                    <img
                      src={require(`../../assets/img/brand/brand_img02.png`)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="brand-item">
                    <img
                      src={require(`../../assets/img/brand/brand_img03.png`)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="brand-item">
                    <img
                      src={require(`../../assets/img/brand/brand_img04.png`)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="brand-item">
                    <img
                      src={require(`../../assets/img/brand/brand_img05.png`)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="brand-item">
                    <img
                      src={require(`../../assets/img/brand/brand_img06.png`)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="brand-item">
                    <img
                      src={require(`../../assets/img/brand/brand_img03.png`)}
                      alt=""
                    />
                  </div>
                </div>
              </SliderCom>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Hero;
