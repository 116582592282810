import React from "react";

function PolicyPage() {
    return (
        <>
            <section className="features-area pt-120 pb-90">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            <span className="sub-title tg-element-title">Privacy Policy</span>
                            <h2 className="title tg-element-title">Introduction</h2>
                        </div>
                        <p className="li1">
                        The Palm Group("we", "our", or "us") is committed to protecting and 
                        respecting your privacy. This Privacy Policy outlines how we collect, 
                        use, disclose, and protect your personal information when you visit our 
                        website, interact with us, or utilize our services.
                        </p>
                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            {/* <span className="sub-title tg-element-title">Our Vision </span> */}
                            <h2 className="title tg-element-title mt-5">Information We Collect</h2>
                        </div>
                        <p className="li1">
                        We may collect and process the following types of information:
                        </p>
                        <p className="li1">
                        1. Personal Information: This includes your name, email address, 
                        phone number, and any other contact details you provide when you 
                        fill out forms on our website, subscribe to our newsletter, or 
                        engage with us in any other way.
                        </p>
                        <p className="li1">
                        2. Transaction Information: Details of the real estate transactions 
                        you carry out with us, including property preferences, purchase history
                        , and financial details provided during the purchase process.
                        </p>
                        <p className="li1">
                        3. Technical Information: This includes information such as your 
                        IP address, browser type, operating system, and details about your 
                        visit to our site, including the pages you visited and how you
                        navigated through our website.
                        </p>
                        <p className="li1">
                        4. Cookies and Tracking Technologies: We may use cookies and similar
                        tracking technologies to collect information about your interactions
                        with our website.
                        </p>
                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            {/* <span className="sub-title tg-element-title">Our Vision </span> */}
                            <h2 className="title tg-element-title mt-5">How We Use Your Information</h2>
                        </div>
                        <p className="li1">
                        The Palm Group uses your information for the following purposes:
                        </p>
                        <p className="li1">
                        1. To Provide Our Services: We use your information to process transactions,
                        manage your account, and provide you with the services you request.
                        </p>
                        <p className="li1">
                        2. To Improve Our Services: Your information helps us to improve the quality
                        of our offerings, develop new services, and enhance your experience on our website.
                        </p>
                        <p className="li1">
                        3. Marketing and Communication: With your consent, we may use your contact information
                        to send you updates, newsletters, and promotional materials about our properties and services.
                        </p>
                        <p className="li1">
                        4. Legal Compliance: We may use your information to comply with applicable laws, regulations,
                        and legal processes.
                        </p>
                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            {/* <span className="sub-title tg-element-title">Our Vision </span> */}
                            <h2 className="title tg-element-title mt-5">Disclosure of Your Information</h2>
                        </div>
                        <p className="li1">
                        We may share your personal information with:
                        </p>
                        <p className="li1">
                        1. Service Providers: Third-party vendors who provide services on our behalf, such as IT support,
                         marketing, and financial services. These providers are obligated to protect your information and
                          only use it as necessary to perform their services.
                        </p>
                        <p className="li1">
                        2. Legal Authorities: If required by law, we may disclose your information to government agencies,
                         law enforcement, or other entities in order to comply with legal obligations or protect our rights.
                        </p>
                        <p className="li1">
                        3. Business Transfers: In the event of a merger, acquisition, or sale of all or part of our assets,
                         your personal information may be transferred to the new entity.
                        </p>
                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            {/* <span className="sub-title tg-element-title">Our Vision </span> */}
                            <h2 className="title tg-element-title mt-5">Your Rights</h2>
                        </div>
                        <p className="li1">
                        You have the right to:
                        </p>
                        <p className="li1">
                        1. Access Your Information: Request access to the personal information we hold about you.
                        </p>
                        <p className="li1">
                        2. Correct Your Information: Request corrections to any inaccurate or incomplete personal information.
                        </p>
                        <p className="li1">
                        3. Withdraw Consent: Withdraw your consent to our use of your personal information at any time, subject 
                        to legal or contractual restrictions.
                        </p>
                        <p className="li1">
                        4. Opt-Out of Marketing: Unsubscribe from our marketing communications at any time by following the 
                        instructions provided in the communication or contacting us directly.
                        </p>
                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            {/* <span className="sub-title tg-element-title">Our Vision </span> */}
                            <h2 className="title tg-element-title mt-5">Security</h2>
                        </div>
                        <p className="li1">
                        The Palm Group takes the security of your personal information seriously. We implement appropriate technical
                         and organizational measures to protect your data from unauthorized access, loss, or disclosure.
                        </p>
                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            {/* <span className="sub-title tg-element-title">Our Vision </span> */}
                            <h2 className="title tg-element-title mt-5">Data Retention</h2>
                        </div>
                        <p className="li1">
                        We will retain your personal information for as long as necessary to fulfill the purposes for
                         which it was collected, including any legal or regulatory obligations.
                        </p>
                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            {/* <span className="sub-title tg-element-title">Our Vision </span> */}
                            <h2 className="title tg-element-title mt-5">Changes to This Privacy Policy</h2>
                        </div>
                        <p className="li1">
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page
                         with an updated revision date. We encourage you to review this policy periodically to stay
                          informed about how we are protecting your information.
                        </p>
                        <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                            {/* <span className="sub-title tg-element-title">Our Vision </span> */}
                            <h2 className="title tg-element-title mt-5">Contact Us</h2>
                        </div>
                        <p className="li1">
                        If you have any questions or concerns about this Privacy Policy or how we handle your
                         personal information, please contact us at: <br/> The Palm Group
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PolicyPage;
